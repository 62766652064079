import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

const Form = tw.form`mt-8 lg:w-3/4 md:mt-10 text-sm flex flex-col`
export const Input = tw.input`border-2 px-6 py-4 rounded-full mb-2 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-4 rounded-full focus:outline-none hover:bg-primary-900 transition duration-300`

export default ({
  submitButtonText = "Sign Up",
  // formAction = "#",
  formMethod = "post",
  mailchimpHiddenInputs,
  children,
}) => {
  return (
    // action={formAction}
    <Form
      name={'sign-up'}
      method={formMethod}
      netlify
    >
      {mailchimpHiddenInputs}
      {children ?
        children :
        <>
          <input type="hidden" name="form-name" value="sign-up" />
          <Input type="text" name="first_name" placeholder="Your First Name" />
          <Input type="email" name="email" placeholder="Email Address" />
        </>
      }
      <SubmitButton type="submit">{submitButtonText}</SubmitButton>
    </Form>
  );
};

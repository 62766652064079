import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header, { LogoLink, NavLinks, NavLink, PrimaryLink } from "../headers/light.js";
import logo from "images/jobably-1.svg";

import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import DesignIllustration from "../../images/design-illustration-2.svg";

import Form, { Input } from "components/forms/JobablySignUp.js"

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  .inputs {

  }
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

const CustomersLogoStrip = styled.div`
  ${tw`mt-12 lg:mt-20`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`mt-4 w-full lg:pr-16 xl:pr-32 opacity-50`}
  }
`;

const jobablyLogoLink = (
  <LogoLink href="/">
    <img src={logo} alt="Jobably logo" />
  </LogoLink>
)



export default ({ roundedHeaderButton }) => {
  const jobablyLinks = [
    <NavLinks key={1}>
      {/* <NavLink href="/#">Contact Us</NavLink> */}
      <NavLink href="/#" tw="lg:ml-4! hidden">
        Looking for <span tw="text-primary-500">hired hands?</span>
      </NavLink>
      <PrimaryLink css={roundedHeaderButton && tw`rounded-full hidden`}href="/#">Sign Up</PrimaryLink>
    </NavLinks>
  ]

  return (
    <>
      <Header roundedHeaderButton={roundedHeaderButton} logoLink={jobablyLogoLink} links={jobablyLinks} />
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>
              Find gigs,<br />Get paid&nbsp;<span tw="text-primary-500">reliably.</span>
            </Heading>
            <Paragraph>
              Jobably sources quality gigs so you can focus on finding income.
              Join us for reliable jobs, guaranteed payments and upskilling opportunities
            </Paragraph>
            <Form
              formMethod="POST"
            />
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img tw="min-w-0 w-full max-w-lg xl:max-w-3xl" src={DesignIllustration} alt="Design Illustration" />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};
